import React, { useContext, useEffect } from 'react';
import DataContext from '../../context/DataContext';
import { useTranslation } from 'react-i18next';
import { muutaSelaimenMetatietoja } from '../../utils/helpers';
import VaittamatLomake from '../../components/VaittamatLomake';
import KuntoutuksenTiedot from '../../components/KuntoutuksenTiedot';

export default function PalautelomakeSivu() {
  const { setKayttajanTiedot } = useContext(DataContext);
  const { t } = useTranslation();

  useEffect(() => {
    muutaSelaimenMetatietoja(t('palauteLomake.title'));
    setKayttajanTiedot(prevState => (
      {
        ...prevState,
        'nakyma': 'palauteLomake'
      }));
  }, []);

  return (
    <>
      <h1>{t('palauteLomake.otsikko')}</h1>

      <div className='navigointivaihe'>{t('navigointivaihe.teksti')} 2/3</div>

      <aside className='bd-callout au-bd-callout-info'>
        <div className='au-bd-callout-icon'>
          <p>{t('palauteLomake.infoteksti.teksti1')}</p>
          <p aria-hidden='true'>{t('palauteLomake.infoteksti.teksti2')}</p>
          <p>{t('palauteLomake.infoteksti.teksti3')}</p>
        </div>
      </aside>

      <KuntoutuksenTiedot />

      <div>
        <h2>{t('palauteLomake.palauteOtsikko')}</h2>
        <p>{t('palauteLomake.kyselyOhje')}</p>
      </div>

      <VaittamatLomake/>
    </>
  );
}
