export function muutaSelaimenMetatietoja(otsikko, kielikoodi) {
    if (otsikko) {
        document.title = otsikko;
    }
    if (kielikoodi) {
        document.documentElement.lang = kielikoodi;
    }
}

export async function odotaSekunteja(kesto) {
    await new Promise(response => {
        setTimeout(response, kesto * 1000);
    });
}

export function onkoObjektiTyhja(object) {
    return JSON.stringify(object) === JSON.stringify({});
}

export function tyontarkenne(toimenpide) {
    if (!toimenpide) {
        return '';
    }
    return `tyontarkenne_${toimenpide.toUpperCase()}`;
}

export function applicationUrl() {
    return process.env.NODE_ENV === 'local' ? 'http://localhost:8080/' : '';
}
