import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DataContext from '../../context/DataContext';
import { tyontarkenne } from '../../utils/helpers';

export default function KuntoutuksenTiedot() {
    const { kayttajanTiedot } = useContext(DataContext);
    const { toimenpide, toimipisteNimi } = kayttajanTiedot;
    const { t } = useTranslation();
    const tyontarkenneKey = tyontarkenne(toimenpide);
    const tyontarkenneValue = t(tyontarkenneKey);

    return (
        <div>
            <h2>{t('palauteLomake.kuntoutusOtsikko')}</h2>
            <strong><div>{t('palauteLomake.kuntoutuspalvelu')}</div></strong>
            <p>{tyontarkenneValue}</p>
            <strong><div>{t('palauteLomake.palveluntuottaja')}</div></strong>
            <p>{toimipisteNimi}</p>
        </div>
    );
}
