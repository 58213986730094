import React, { useRef, useContext } from 'react';
import DataContext from '../../context/DataContext';
import { useNavigate } from 'react-router';
import { withIdleTimer } from 'react-idle-timer';
import log from 'loglevel';
import { AIKAKATKAISUAIKA } from '../../utils/vakiot';

/**
 * Kustomoitu komponentti, jolla saadaan IdleTimer toimimaan.
 * Kts. https://idletimer.dev/docs/getting-started/new#removal-of-idletimer--component
 */
class IdleTimerComponent extends React.Component {
  render () {
    return this.props.children;
  }
}

const IdleTimer = withIdleTimer(IdleTimerComponent);

export default function Aikakatkaisija() {
    const { setKayttajanTiedot } = useContext(DataContext);
    const idleTimerRef = useRef(null);
    const navigate = useNavigate(null);
    const onIdle = () => {
        log.info(`Käyttäjä ei ole ollut aktiivinen, aikakatkaisu on ${AIKAKATKAISUAIKA} ms. Siirrytään takaisin kirjautumissivulle.`);
        setKayttajanTiedot(prevState => ({
            ...prevState,
            'kirjautunut': false,
            'virhe': false,
            'kirjautumisavain': null,
            'palauteLahetetty': false,
            'palveluntuottajaNimi': '',
            'lahetyspaiva': '',
            'toimenpide': '',
            'toimipisteId': '',
            'toimipisteNimi': '',
            'sopimusNumero': ''
        }));

        navigate('/kirjautuminen');
    };

    return (
        <IdleTimer data-testid='idle-timer-test' ref={idleTimerRef} timeout={AIKAKATKAISUAIKA} onIdle={onIdle} />
    );
}
