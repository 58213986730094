import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import i18NextBackend from 'i18next-http-backend';

i18n
  .use(i18NextBackend)
  .use(initReactI18next)
  .init({
    'debug': true,
    'fallbackLng': 'fi',
    'lng': 'fi',
    'defaultNS': 'translation',
    'ns': 'translation',
    'whitelist': ['fi', 'sv'],
    'supportedLngs': ['fi', 'sv'],
    'backend': {
      'loadPath': '/locales/{{lng}}/{{ns}}.json'
    },
    'react': {
      'useSuspense': true
    }
  });

export default i18n;
