import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import DataContext from "../../context/DataContext";
import { useTranslation } from "react-i18next";
import log from "loglevel";
import {
  postVastaukset,
  getVaittamat,
  getVastausvaihtoehdot,
} from "../../api/Queries";
import Vaittama from "../Vaittama";
import Luuranko from "./Luuranko";
import { InputGroup, Button, Spinner } from "kela-design-system";

import "./styles.css";

export default function VaittamatLomake() {
  log.info("VaittamatLista");

  const {
    kayttajanTiedot,
    setKayttajanTiedot,
    latauksenTiedot,
    setLatauksenTiedot,
  } = useContext(DataContext);

  const navigate = useNavigate(null);
  const [vaittamat, setVaittamat] = useState();
  const [vastausvaihtoehdot, setVastausvaihtoehdot] = useState();
  const [vastaukset, setVastaukset] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const haeVaittamatLista = async () => {
      log.info("VaittamatLista - useEffect");
      await getVaittamat()
        .then(({ data, error }) => {
          if (error) {
            throw Error(error);
          }
          log.info("Vaittamat haettu");
          log.debug(data);

          if (data) {
            setVaittamat(data);
          }
        })
        .catch((error) => {
          log.debug(error);
        });

      await getVastausvaihtoehdot()
        .then(({ data, error }) => {
          if (error) {
            throw Error(error);
          }
          log.info("Vastausvaihtoehdot haettu");
          log.debug(data);

          if (data) {
            setVastausvaihtoehdot(data);
          }
        })
        .catch((error) => {
          log.debug(error);
        });
    };

    haeVaittamatLista();
  }, []);

  const handleSubmit = async (e) => {
    log.info("PalauteLomakeSivu - handleSubmit");
    e.preventDefault();

    setLatauksenTiedot(() => ({
      kaynnissa: true,
      valmis: false,
    }));

    const {
      palveluntuottajaNimi,
      lahetyspaiva: lahetysPv,
      kirjautumisavain,
      toimenpide,
      toimipisteId,
      toimipisteNimi,
      sopimusNumero: sopimusNro,
    } = kayttajanTiedot;

    const data = {
      palveluntuottajaNimi,
      lahetysPv,
      kirjautumisavain,
      toimenpide,
      toimipisteId,
      toimipisteNimi,
      sopimusNro,
      vastausList: vastaukset,
    };

    log.info(data);

    setLatauksenTiedot(() => ({
      kaynnissa: true,
      valmis: false,
    }));

    await postVastaukset(data)
      .then(({ result, error }) => {
        if (error) {
          throw Error(error);
        }
        log.info(`Vastaukset lähetetty: ${result}`);
        setKayttajanTiedot((prevState) => ({
          ...prevState,
          palauteLahetetty: true,
        }));
        navigate("/palaute-lahetetty");
      })
      .catch((error) => {
        log.debug(error);
        log.error("Jotakin meni pieleen vastausten lähettämisessä");
        navigate("/virhe");
      })
      .finally(() => {
        setLatauksenTiedot(() => ({
          kaynnissa: false,
          valmis: true,
        }));
      });
  };

  log.info("Show content");
  return vaittamat && vastausvaihtoehdot ?
  (
    <InputGroup as="form" onSubmit={handleSubmit}>
      {vaittamat.map((vaittama, index) => (
        <Vaittama
          key={`vaittama_${index}`}
          vaittama={vaittama}
          vaittamaIndex={index}
          vastausvaihtoehdot={vastausvaihtoehdot}
          vastaukset={vastaukset}
          setVastaukset={setVastaukset}
        />
      ))}
      <Button
        id="btnLaheta"
        type="submit"
        className="btn btn-primary"
        disabled={latauksenTiedot.kaynnissa}
        iconBefore={latauksenTiedot.kaynnissa ? <Spinner /> : null}
      >
        {t("palauteLomake.lahetaNappi")}
      </Button>
    </InputGroup>
    ) :
    (
     <Luuranko />
    );
}
