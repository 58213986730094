import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DataContext from '../../context/DataContext';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownMenuItem } from 'kela-design-system';
import IconGlobe from 'kela-design-system/dist/icons/ui/IconGlobe';
import log from 'loglevel';

import { muutaSelaimenMetatietoja } from '../../utils/helpers';
import './styles.css';

export default function Kielenvalinta() {
    const [isOpen, setIsOpen] = useState(false);
    const [tuetutKielet, setTuetutKielet] = useState([]);
    const { t, i18n } = useTranslation();
    const { 'kayttajanTiedot': { nakyma } } = useContext(DataContext);
    const changeLanguage = code => {
        i18n.changeLanguage(code, () => {
            muutaSelaimenMetatietoja(t(`${nakyma}.title`), code);
        });

        setIsOpen(false);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const supportedLanguages = i18n.options.supportedLngs;

        // Filtteröidään 'cimode' pois tuetuista kielistä. Tämä tulee defaulttina.
        const filteredLanguages = supportedLanguages.filter(lng => lng !== 'cimode');
        log.info(`Tuetut kielet: ${filteredLanguages}`);
        setTuetutKielet(filteredLanguages);
    }, []);

    return (
        <Dropdown isOpen={isOpen} onClick={toggleDropdown}util='d-sm-block'>
            <DropdownToggle id='btnKielenvalinta' variant='secondary text-primary'>
                <IconGlobe aria-label='kielenvalinnan kuvake' alt='Maapallon kaltainen kuvake' tabIndex='-1' />
                {t(i18n.language)}
            </DropdownToggle>
            <DropdownMenu>
                {tuetutKielet.map((kieliKoodi, index) => (
                    <DropdownMenuItem key={index} as='button' active={i18n.language === kieliKoodi} onClick={() => changeLanguage(kieliKoodi)}>
                        {t(kieliKoodi)}
                    </DropdownMenuItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
}
