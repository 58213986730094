import axios from 'axios';
import { applicationUrl } from '../utils/helpers';
import log from 'loglevel';

axios.defaults.baseUrl = applicationUrl;

export const getVaittamat = () => axios.get('api/vaittamat')
    .then(result => {
        log.info('/api/vaittamat requesti tehtiin onnistuneesti');
        log.info(result.data);
        return { 'data': result.data };
    })
    .catch(error => {
        log.error('/api/vaittamat requesti palautti virheen');
        logitaVirheet(error);
        return { 'error': error };
    });

export const getVastausvaihtoehdot = () => axios.get('api/vastausvaihtoehdot')
    .then(result => {
        log.info('/api/vastausvaihtoehdot requesti tehtiin onnistuneesti');
        log.info(result.data);
        return { 'data': result.data };
    })
    .catch(error => {
        log.error('/api/vastausvaihtoehdot requesti palautti virheen');
        logitaVirheet(error);
        return { 'error': error };
    });

export const postVastaukset = data => axios.post('api/lahetaVastaukset', data)
    .then(result => {
        log.info('/api/lahetaVastaukset requesti tehtiin onnistuneesti');
        log.info(result.data);
        return { 'result': result.data };
    })
    .catch(error => {
        log.error('/api/lahetaVastaukset requesti palautti virheen');
        logitaVirheet(error);
        return { 'error': error };
    });

export const getPalautekehote = kirjautumiskoodi => axios.get(`api/palautekehote/${kirjautumiskoodi}`)
    .then(result => {
        log.info('/api/palautekehote requesti tehtiin onnistuneesti');
        log.info(result.data);
        return { 'data': result.data };
    })
    .catch(error => {
        log.error('/api/palautekehote requesti palautti virheen');
        logitaVirheet(error);
        return { 'error': error };
    });

export const initLogLevel = () => axios.get('api/initLogLevel')
    .then(result => {
      log.setLevel(result.data);
      console.log(`Lokitustaso: ${log.getLevel()}`);
    });

function logitaVirheet(error) {
    if (error.response) {
        log.debug('Response ongelma | Status: ', error.response.status);
        return error.response.status;
    } else if (error.request) {
        log.debug('Request ongelma ', error.request);
        return error.request;
    }

    log.debug('Virhe ', error.message);
    return error.message;
}
